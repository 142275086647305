import React from "react";

import { contactForm } from "./contact.module.scss";

import Layout from "../layouts/Layout";
import Link from "../components/Link";
import ContactBanner from "../partials/ContactBanner";
import ContactForm from "../partials/ContactForm";

import { useMediaQuery } from "../utils/hooks";

const ContactPage = props => {
  const isMobile = useMediaQuery("(max-width: 575px)");

  return (
    <Layout
      pageTitle="Contact us | Brandcrush"
      ogImage="/images/seo/contact.jpg"
      ogDescription="Connect with the Brandcrush team today to learn more about Brandcrush, get help booking a campaign, or discuss subscription options for partners."
    >
      <ContactBanner />
      <section className={contactForm}>
        <ContactForm />
      </section>
    </Layout>
  );
};

export default ContactPage;
