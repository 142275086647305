import React from "react";

import { base, link } from "./ContactBanner.module.scss";

import Link from "../components/Link";

const CaseStudiesBanner = props => {
  return (
    <section className={base}>
      <h1>Contact us</h1>
      <p>
        Have questions about Brandcrush? If we can't answer your question on our{" "}
        <Link className={link} href="/FAQs">
          FAQ page
        </Link>
        , feel free to send a message via the form below.
      </p>
      <img src="/images/cloud-contact.svg" alt="" />
      <img src="/images/contact-banner.svg" alt="" />
    </section>
  );
};

export default CaseStudiesBanner;
